export const KITCHEN_ISLAND = {
	id: '2-kitchen-island',
	name: 'Kitchen Island',
	levels: [
		{
			tileSize: 256,
			size: 256,
			fallbackOnly: true,
		},
		{
			tileSize: 512,
			size: 512,
		},
		{
			tileSize: 512,
			size: 1024,
		},
		{
			tileSize: 512,
			size: 2048,
		},
	],
	faceSize: 2048,
	initialViewParameters: {
		yaw: -0.0005943935503509579,
		pitch: 0.6074701054373062,
		fov: 1.1252406777618285,
	},
	linkHotspots: [
	],
	infoHotspots: [
		{
			class: 'intro-starter',
			yaw: -0.6296041741844114,
			pitch: 0.6541907464738905,
			title: 'Stoneware Starter Set',
			text: `
				<div class="multi-product-container">
					<div class="multi-product-wrapper">
						<section class="section">
							<ul class="multi-product-list">
								<h3 class="section-title">Stoneware Starter Set <b>(#100292)</b></h3>
									You can make perfect pizzas every time with the Pizza Stone, create easy 
									sheet-pan dinners on the Stone Bar Pan, and bake delicious banana bread 
									in the Stone Loaf Pan.
									<br/><br/>
									Great for new cooks, first-time homeowners, college students, and empty 
									nesters ready to refresh their kitchen!
							</ul>
						</section>
					</div>
				</div>
			`,
			images: [
				`<img width="640" alt="vrk-fall24-kitchen-stoneware-set-02.jpg" src="https://pamperedchef.widen.net/content/5k85rti50a/jpeg/vrk-fall24-kitchen-stoneware-set-02.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-stoneware-set-03.jpg" src="https://pamperedchef.widen.net/content/ravvaott1o/jpeg/vrk-fall24-kitchen-stoneware-set-03.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-stoneware-set-04.jpg" src="https://pamperedchef.widen.net/content/i5mhy2gmca/jpeg/vrk-fall24-kitchen-stoneware-set-04.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
			],
			videos: [
				`<div style="position:relative;width:100%;height:0;padding-bottom:56.25%;"><iframe src="https://pamperedchef.widen.net/view/video/vdtkygdz1k/video-tiktok-homemade-pizza-dough-usca.mp4?u=intbhg" webkitallowfullscreen mozallowfullscreen allowfullscreen title="Video for &quot;video-tiktok-homemade-pizza-dough-usca.mp4&quot;" frameborder="0" allowtransparency="true" scrolling="no" style="position:absolute;top:0;left:0;width:100%;height:100%;" ></iframe></div>`,
			],
		},
		{
			yaw: 0.09754898793619304,
			pitch: 0.5661444781747988,
			title: 'Cutlery Basics Set',
			text: `
				<div class="multi-product-container">
					<div class="multi-product-wrapper">
						<section class="section">
							<ul class="multi-product-list">
								<h3 class="section-title">Cutlery Basics Set <b>(#101419)</b></h3>
									All the essential cutting tools new kitchens need. First, you need a few 
									different size cutting boards for any prep task. Then, a variety of knife sizes 
									and styles for a variety of foods—the right knife for the job makes a 
									difference! And lastly, quality steak knives for enjoying those heartier meals.
									<br/><br/>
									Great for new cooks, first-time homeowners, college students, and empty 
									nesters ready to refresh their kitchen!
							</ul>
						</section>
					</div>
				</div>
			`,
			images: [
				`<img width="640" alt="vrk-fall24-kitchen-cutlery-set-02.jpg" src="https://pamperedchef.widen.net/content/wykkhg3wsy/jpeg/vrk-fall24-kitchen-cutlery-set-02.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-cutlery-set-03.jpg" src="https://pamperedchef.widen.net/content/wx4mk7xtbl/jpeg/vrk-fall24-kitchen-cutlery-set-03.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-cutlery-set-04.jpg" src="https://pamperedchef.widen.net/content/geiplrrpwa/jpeg/vrk-fall24-kitchen-cutlery-set-04.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
			],
			videos: [
				`<div style="position:relative;width:100%;height:0;padding-bottom:56.25%;"><iframe src="https://pamperedchef.widen.net/view/video/i5bgiz0xtk/video-product-ss23-coated-knife-set-social-loop-usca.mp4?u=intbhg" webkitallowfullscreen mozallowfullscreen allowfullscreen title="Video for &quot;video-product-ss23-coated-knife-set-social-loop-usca.mp4&quot;" frameborder="0" allowtransparency="true" scrolling="no" style="position:absolute;top:0;left:0;width:100%;height:100%;" ></iframe></div>`
			],
		},
		{
			yaw: 0.5808080655022643,
			pitch: 0.5253043154516455,
			title: 'Cookie Baking Set',
			text: `
				<div class="multi-product-container">
					<div class="multi-product-wrapper">
						<section class="section">
							<ul class="multi-product-list">
								<h3 class="section-title">Cookie Baking Set <b>(#101420)</b></h3>
									The perfect way to get into baking or stock your kitchen with quality baking 
									essentials.
									With two heavy-duty <b>Cookie Sheets</b> to bake up a storm, a <b>Medium Scoop</b> 
									for perfectly portioned cookies, two <b>Stackable Cooling Racks</b> for cooling 
									and decorating, and a <b>Teal Mini Nylon Serving Spatula</b> to easy move and 
									serve your delicious creations.
									<br/><br/>
									Great for new cooks, first-time homeowners, college students, and empty 
									nesters ready to refresh their kitchen!
							</ul>
						</section>
					</div>
				</div>
			`,
			images: [
				`<img width="640" alt="vrk-fall24-kitchen-cookie-set-01.jpg" src="https://pamperedchef.widen.net/content/pc6wlek2rl/jpeg/vrk-fall24-kitchen-cookie-set-01.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-cookie-set-02.jpg" src="https://pamperedchef.widen.net/content/w7jlkmo6mj/jpeg/vrk-fall24-kitchen-cookie-set-02.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-cookie-set-03.jpg" src="https://pamperedchef.widen.net/content/hdyfi7vkuo/jpeg/vrk-fall24-kitchen-cookie-set-03.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-cookie-set-04.jpg" src="https://pamperedchef.widen.net/content/ht3kkgfuhi/jpeg/vrk-fall24-kitchen-cookie-set-04.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
			],
		},
		{
			yaw: -0.3836619982848326, 
			pitch: 0.20475223119669828,
			title: 'Utensil Starter Set',
			text: `
				<div class="multi-product-container">
					<div class="multi-product-wrapper">
						<section class="section">
							<ul class="multi-product-list">
								<h3 class="section-title">Utensil Starter Set <b>(#101418)</b></h3>
									Every cook needs quality kitchen essentials. This set has a mix of durable, 
									silicone basics, along with a few multi-functional tools that make it super 
									easy to do many tasks with just one tool, and less dishes! All together, they 
									easily fit into the compact and sleek utensil holder that looks great on any 
									counter.
									<br/><br/>
									Great for new cooks, first-time homeowners, college students, and empty 
									nesters ready to refresh their kitchen!
							</ul>
						</section>
					</div>
				</div>
			`,
			images: [
				`<img width="640" alt="vrk-fall24-kitchen-utensil-set-03.jpg" src="https://pamperedchef.widen.net/content/i1ld1kj2xl/jpeg/vrk-fall24-kitchen-utensil-set-03.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-utensil-set-04.jpg" src="https://pamperedchef.widen.net/content/q2trshiakq/jpeg/vrk-fall24-kitchen-utensil-set-04.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
			],
			videos: [
				`<div style="position:relative;width:100%;height:0;padding-bottom:56.25%;"><iframe src="https://pamperedchef.widen.net/view/video/sqzih8dnsq/video-product-whisk-tongs-usca.mp4?u=intbhg" webkitallowfullscreen mozallowfullscreen allowfullscreen title="Video for &quot;video-product-whisk-tongs-usca.mp4&quot;" frameborder="0" allowtransparency="true" scrolling="no" style="position:absolute;top:0;left:0;width:100%;height:100%;" ></iframe></div>`,
				`<div style="position:relative;width:100%;height:0;padding-bottom:56.25%;"><iframe src="https://pamperedchef.widen.net/view/video/lfpki6ejpx/video-product-mix-n-chop-spatula-2022-4x5-usca.mp4?u=intbhg" webkitallowfullscreen mozallowfullscreen allowfullscreen title="Video for &quot;video-product-mix-n-chop-spatula-2022-4x5-usca.mp4&quot;" frameborder="0" allowtransparency="true" scrolling="no" style="position:absolute;top:0;left:0;width:100%;height:100%;" ></iframe></div>`,
			],
		},
		{
			yaw: 0.012833724448508832, 
			pitch: 0.11378186415693747,
			title: 'Brilliance Cookware Set',
			text: `
				<div class="multi-product-container">
					<div class="multi-product-wrapper">
						<section class="section">
							<ul class="multi-product-list">
								<h3 class="section-title">5-Piece Brilliance Nonstick Cookware Set <b>(#100772)</b></h3>
									Start your cookware collection or add a pop of color to it with this five-piece 
									set! It includes all the basic pieces you'll need to make delicious, no-fuss 
									meals every day. With high-quality nonstick coatings on the inside and 
									outside of this cookware, you can be sure your food will slide right out—and 
									because it's dishwasher-safe, cleanup has never been easier.
									<br/><br/>
									Great for new cooks, first-time homeowners, college students, and empty 
									nesters ready to refresh their kitchen!
							</ul>
						</section>
					</div>
				</div>
			`,
			images: [
				`<img width="640" alt="vrk-fall24-kitchen-cookware-03.jpg" src="https://pamperedchef.widen.net/content/w0jqaqoecm/jpeg/vrk-fall24-kitchen-cookware-set-03.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
				`<img width="640" alt="vrk-fall24-kitchen-cookware-04.jpg" src="https://pamperedchef.widen.net/content/dr4o1v9mzw/jpeg/vrk-fall24-kitchen-cookware-set-04.jpg?w=640&keep=c&crop=yes&color=cccccc&quality=80">`,
			],
			videos: [
				`<div style="position:relative;width:100%;height:0;padding-bottom:56.25%;"><iframe src="https://pamperedchef.widen.net/view/video/qmzocoozsq/video-tiktok-how-to-cook-and-brown-ground-beef-usca.mp4?u=intbhg" webkitallowfullscreen mozallowfullscreen allowfullscreen title="Video for &quot;video-tiktok-how-to-cook-and-brown-ground-beef-usca.mp4&quot;" frameborder="0" allowtransparency="true" scrolling="no" style="position:absolute;top:0;left:0;width:100%;height:100%;" ></iframe></div>`,
				`<div style="position:relative;width:100%;height:0;padding-bottom:56.25%;"><iframe src="https://pamperedchef.widen.net/view/video/b0ae5frcvx/video-tiktok-how-to-make-a-perfect-omelet-in-5-minutes-usca.mp4?u=intbhg" webkitallowfullscreen mozallowfullscreen allowfullscreen title="Video for &quot;video-tiktok-how-to-make-a-perfect-omelet-in-5-minutes-usca.mp4&quot;" frameborder="0" allowtransparency="true" scrolling="no" style="position:absolute;top:0;left:0;width:100%;height:100%;" ></iframe></div>`,
			],
		}
	],
};
